// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/scanCode/ic_scan.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".scan-qr-code[data-v-b070c866]{position:relative;height:100%;background-color:#fff}.scan-qr-code .content[data-v-b070c866]{position:absolute;top:2.66667rem;left:.37333rem;width:9.25333rem}.scan-qr-code .content .logo[data-v-b070c866]{width:100%;margin-bottom:1.86667rem;text-align:center}.scan-qr-code .content .logo img[data-v-b070c866]{width:2.66667rem;height:2.66667rem;border-radius:50%}.scan-qr-code .content .title[data-v-b070c866]{font-size:.32rem;color:#333}.scan-qr-code .content .van-cell[data-v-b070c866]{border-bottom:.02667rem solid #ebedf0}.scan-qr-code .btn-scan[data-v-b070c866]{width:.8rem;height:.8rem;border:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.scan-qr-code .btn-inquire[data-v-b070c866]{width:9.25333rem;height:1.14667rem;margin:0 auto;margin-top:1.33333rem;border:0;border-radius:.10667rem;background-color:#4d6dff}", ""]);
// Exports
module.exports = exports;
