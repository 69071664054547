<template>
    <div class="scan-qr-code">
        <div class="content">
            <div class="logo">
                <img src="../../assets/images/scanCode/ic_source.png"/>
            </div>
            <div class="title">溯源码</div>
            <van-field
                v-model="qrcode"
                center
                clearable
                label=""
                placeholder="请输入溯源码"
                >
                <template #button>
                    <van-button class="btn-scan" size="small" type="primary" @click="scanQrcode"></van-button>
                </template>
            </van-field>
            <van-button class="btn-inquire" size="small" type="primary" @click="Inquire">查询</van-button>
        </div>
    </div>
</template>

<script>
// 页面使用
import wx from 'weixin-js-sdk';
import { getBatchsUrl  } from '@/api/suyuan'
export default {
    data() {
        return {
            qrcode: '',
        }
    },
    methods: {
        scanQrcode() {
            let path = `/pages/scan/scan`
	        wx.miniProgram.navigateTo({
	          url: path,
	        })
        },
        /** 查询 */
        Inquire() {
            getBatchsUrl({batchsCode: this.qrcode}).then(res => {
                if (res.code == 200) {
                    let url = '';
                    if (res.data.plan == 'a') {
                        url = res.data.url + 'planA'
                    } else if (res.data.plan == 'b') {
                        url = res.data.url + 'planB'
                    } else if (res.data.plan == 'c') {
                        url = res.data.url + 'planC'
                    }
                    wx.miniProgram.navigateTo({
                        url: '/pages/suyuan/suyuan?url='+ url+ '&batchsCode='+ res.data.batchsCode +'',
                    })
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .scan-qr-code {
        position: relative;
        height: 100%;
        background-color: #fff;
        .content {
            position: absolute;
            top: 100px;
            left: 14px;
            width: 347px;
            // transform: translate(-50%, -50%);
            .logo {
                width: 100%;
                margin-bottom: 70px;
                text-align: center;
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                }
            }
            .title {
                font-size: 12px;
                color: #333;
            }
            .van-cell {
                border-bottom: 1px solid #ebedf0;
            }
        }
        .btn-scan {
            width: 30px;
            height: 30px;
            border: 0;
            background: url(../../assets/images/scanCode/ic_scan.svg);
            background-size: contain;
        }
        .btn-inquire {
            width: 347px;
            height: 43px;
            margin: 0 auto;
            margin-top: 50px;
            border: 0;
            border-radius: 4px;
            background-color: #4D6DFF;
        }
    }
</style>