import { render, staticRenderFns } from "./scanQRCode.vue?vue&type=template&id=b070c866&scoped=true&"
import script from "./scanQRCode.vue?vue&type=script&lang=js&"
export * from "./scanQRCode.vue?vue&type=script&lang=js&"
import style0 from "./scanQRCode.vue?vue&type=style&index=0&id=b070c866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b070c866",
  null
  
)

export default component.exports